var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-4"},_vm._l((_vm.rows),function(row){return _c('v-row',{key:("row-" + (row.id)),attrs:{"no-gutters":""}},_vm._l((row.students),function(student){return _c('v-col',{key:("col-" + (student.id)),class:("col-md-" + (12 / _vm.columns))},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-1 ma-1 d-flex",staticStyle:{"transition":"box-shadow 250ms ease 0s"},attrs:{"elevation":hover ? 6 : 0}},[_c('div',[_c('v-img',{staticClass:"mr-2",staticStyle:{"border-radius":"100%","min-width":"60px","min-height":"60px"},attrs:{"alt":((student.name) + "'s student photo"),"src":"https://via.placeholder.com/60","transition":"scale-transition","width":60,"height":60}})],1),_c('div',[_c('div',[_vm._v(" "+_vm._s(student.name)+" ")]),_c('div',[(student.fundingA)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("A")])]}}],null,true)},[_c('span',[_vm._v("Allied Health Support")])]):_vm._e(),(student.fundingB)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("I")])]}}],null,true)},[_c('span',[_vm._v("Intervention Program")])]):_vm._e(),(student.fundingC)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("P")])]}}],null,true)},[_c('span',[_vm._v("PPL")])]):_vm._e(),(student.filter1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("D")])]}}],null,true)},[_c('span',[_vm._v("Dyslexia")])]):_vm._e(),(student.filter2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("m")])]}}],null,true)},[_c('span',[_vm._v("Autism")])]):_vm._e(),(student.filter3)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mt-1"},'v-chip',attrs,false),on),[_vm._v("H")])]}}],null,true)},[_c('span',[_vm._v("ADHD")])]):_vm._e()],1)])])]}}],null,true)})],1)}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }