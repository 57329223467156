











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Counter extends Vue {
  @Prop({ default: [] }) readonly students!: Array<never>;
  @Prop({ type: Boolean }) readonly large!: boolean;

  get total(): number {
    return this.students.length;
  }
}
