






























import { Component, Prop, Vue } from "vue-property-decorator";
import type { Student } from "@/types";

@Component
export default class GridSystem extends Vue {
  @Prop({ default: 250 }) readonly dimensions!: number;
  @Prop({ default: [] }) readonly students!: Array<Student>;
}
