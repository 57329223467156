import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import StudentOverviewView from "@/views/StudentOverviewView/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/student_overview",
    name: "student overview",
    component: StudentOverviewView,
  },
  {
    path: "/demo/reading_levels",
    name: "reading levels demo",
    component: () =>
      import("@/views/StudentOverviewView/StudentReadingLevelsDemo.vue"),
  },
  {
    path: "/demo/counter",
    name: "counter demo",
    component: () => import("@/views/StudentOverviewView/CounterDemo.vue"),
  },
  {
    path: "/demo/table_view",
    name: "table view demo",
    component: () =>
      import("@/views/StudentOverviewView/TableStudentProfilesDemo.vue"),
  },
  {
    path: "/demo/list_view",
    name: "list view demo",
    component: () => import("@/views/StudentOverviewView/ListViewDemo.vue"),
  },
  {
    path: "/demo/grid_view",
    name: "grid view demo",
    component: () => import("@/views/StudentOverviewView/GridSystemDemo.vue"),
  },
  {
    path: "/developer_information",
    name: "developer information",
    component: () => import("@/views/DeveloperView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
