




















































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import { Student } from "@/types";
import { studentFixtures } from "@/fixtures/studentFixtures";

import TableStudentProfiles from "./TableStudentProfiles.vue";
import GridSystem from "./GridSystem.vue";
import ListView from "./ListView.vue";
import StudentReadingLevels from "../../views/StudentOverviewView/StudentReadingLevels.vue";
import Counter from "../../views/StudentOverviewView/Counter.vue";

@Component({
  components: {
    Counter,
    ListView,
    GridSystem,
    TableStudentProfiles,
    StudentReadingLevels,
  },
})
export default class StudentOverviewView extends Vue {
  @Prop({ default: () => studentFixtures }) readonly students!: Array<Student>;
  @Prop({
    default: [
      "Allied Health Support",
      "Intervention Program",
      "PPL",
      "Dyslexia",
      "Autism",
      "ADHD",
    ],
  })
  readonly filters!: Array<string>;

  zoomLevel = 1;

  fundingA = "Allied Health Support";
  fundingB = "Intervention Program";
  fundingC = "PPL";

  filter1 = "Dyslexia";
  filter2 = "Autism";
  filter3 = "ADHD";

  activeFilters: Array<string> = [];

  clearFiltersHandler(): void {
    this.activeFilters = [];
  }

  get filteredStudents(): Array<Student> {
    var studentsStack = this.students;

    if (this.activeFilters.length) {
      if (this.activeFilters.includes(this.fundingA))
        studentsStack = studentsStack.filter(({ fundingA }) => fundingA);
      if (this.activeFilters.includes(this.fundingB))
        studentsStack = studentsStack.filter(({ fundingB }) => fundingB);
      if (this.activeFilters.includes(this.fundingC))
        studentsStack = studentsStack.filter(({ fundingC }) => fundingC);
      if (this.activeFilters.includes(this.filter1))
        studentsStack = studentsStack.filter(({ filter1 }) => filter1);
      if (this.activeFilters.includes(this.filter2))
        studentsStack = studentsStack.filter(({ filter2 }) => filter2);
      if (this.activeFilters.includes(this.filter3))
        studentsStack = studentsStack.filter(({ filter3 }) => filter3);
    }
    return studentsStack;
  }

  toggleFilterClickHandler(filter: string): void {
    this.activeFilters.includes(filter)
      ? (this.activeFilters = this.activeFilters.filter((f) => f != filter))
      : this.activeFilters.push(filter);
  }
}
