






















import { Component, Prop, Vue } from "vue-property-decorator";
import { Student } from "@/types";

const fundingA = "Allied Health Support";
const fundingB = "Intervention Program";
const fundingC = "PPL";

const filter1 = "Dyslexia";
const filter2 = "Autism";
const filter3 = "ADHD";

@Component
export default class TableStudentProfiles extends Vue {
  @Prop({ default: [] }) readonly students!: Array<Student>;

  activeFilters: Array<string> = [];

  get headers() {
    return [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Funding",
        value: "funding",
        sortable: false,
      },
      {
        text: "Year Level",
        value: "yearLevel",
      },
    ];
  }

  toggleFilterClickHandler(filter: string): void {
    this.$emit("filterUpdated", filter);
    this.activeFilters = this.activeFilters.includes(filter)
      ? this.activeFilters.filter((f) => f != filter)
      : this.activeFilters.concat(filter);
  }

  activeFilter(filter: string): boolean {
    return this.activeFilters.includes(filter);
  }

  get filteredStudents(): Array<Student> {
    var studentsStack = this.students;

    if (this.activeFilters.length) {
      if (this.activeFilters.includes(fundingA))
        studentsStack = studentsStack.filter(({ fundingA }) => fundingA);
      if (this.activeFilters.includes(fundingB))
        studentsStack = studentsStack.filter(({ fundingB }) => fundingB);
      if (this.activeFilters.includes(fundingC))
        studentsStack = studentsStack.filter(({ fundingC }) => fundingC);
      if (this.activeFilters.includes(filter1))
        studentsStack = studentsStack.filter(({ filter1 }) => filter1);
      if (this.activeFilters.includes(filter2))
        studentsStack = studentsStack.filter(({ filter2 }) => filter2);
      if (this.activeFilters.includes(filter3))
        studentsStack = studentsStack.filter(({ filter3 }) => filter3);
    }
    return studentsStack;
  }

  studentFilters(student: Student): Array<string> {
    return Object.entries({
      [fundingA]: student.fundingA,
      [fundingB]: student.fundingB,
      [fundingC]: student.fundingC,
      [filter1]: student.filter1,
      [filter2]: student.filter2,
      [filter3]: student.filter3,
    })
      .filter(([, value]) => value)
      .map(([filter]) => filter);
  }
}
