












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Chart,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";

import { Student } from "@/types";

Chart.register(
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
  AnnotationPlugin
);

@Component
export default class StudentReadingLevels extends Vue {
  @Prop({ default: () => [] }) readonly students!: Array<Student>;
  @Prop({ default: 400 }) readonly height!: number;
  @Prop({ default: 1000 }) readonly width!: number;
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly subtitle!: string;
  @Prop({ default: 5 }) readonly benchmark!: number;
  @Prop({ default: "Benchmark" }) readonly benchmarkLabel!: string;
  @Prop({ default: "Y Axis" }) readonly yAxisLabel!: string;
  @Prop({ default: "X Axis" }) readonly xAxisLabel!: string;

  chart: Chart | null = null;

  @Watch("students")
  onStudentsChange() {
    if (!this.chart) return;
    if (!this.chart.data) return;
    const data: Array<{ label: string; value: number; color: string }> =
      Array.from({ length: 15 }, (_, i) => i + 1).map((x) => ({
        label: `L${x}`,
        value: this.students.filter(({ yearLevel }) => yearLevel === x).length,
        color: "pink",
      }));

    this.chart.data = {
      labels: data.map(({ label }) => label),
      datasets: [
        {
          data: data.map(({ value }) => value),
          backgroundColor: data.map(({ color }) => color),
        },
      ],
    };

    if (!this.chart.options?.scales?.y) return;
    (this.chart.options.scales.y.max =
      Math.max(...data.map(({ value }) => value)) + 1),
      this.chart.update();
  }

  @Watch("benchmark")
  onBenchmarkChange() {
    if (
      this.chart?.options?.plugins?.annotation?.annotations &&
      "line1" in this.chart.options.plugins.annotation.annotations &&
      this.chart.options.plugins.annotation.annotations.line1?.xMin
    ) {
      this.chart.options.plugins.annotation.annotations.line1.xMin =
        this.benchmark;
      this.chart.options.plugins.annotation.annotations.line1.xMax =
        this.benchmark;
      this.chart.update();
    }
  }

  @Watch("benchmarkLabel")
  onBenchmarkLabelChange() {
    if (
      this.chart?.options?.plugins?.annotation?.annotations &&
      "line1" in this.chart.options.plugins.annotation.annotations &&
      this.chart.options.plugins.annotation.annotations.line1 &&
      "label" in this.chart.options.plugins.annotation.annotations.line1 &&
      this.chart.options.plugins.annotation.annotations.line1?.label?.content
    ) {
      this.chart.options.plugins.annotation.annotations.line1.label.content =
        this.benchmarkLabel;
      this.chart.update();
    }
  }

  @Watch("xAxisLabel")
  onXAxisLabelChange() {
    if (
      this.chart?.options?.scales?.x &&
      "title" in this.chart.options.scales.x &&
      this.chart.options.scales.x.title?.text
    ) {
      this.chart.options.scales.x.title.text = this.xAxisLabel;
      this.chart.update();
    }
  }

  @Watch("yAxisLabel")
  onYAxisLabelChange() {
    if (
      this.chart?.options?.scales?.y &&
      "title" in this.chart.options.scales.y &&
      this.chart.options.scales.y.title?.text
    ) {
      this.chart.options.scales.y.title.text = this.yAxisLabel;
      this.chart.update();
    }
  }

  mounted() {
    const canvas = document.getElementById("chart");
    if (!(canvas instanceof HTMLCanvasElement)) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const data: Array<{ label: string; value: number; color: string }> =
      Array.from({ length: 15 }, (_, i) => i + 1).map((x) => ({
        label: `L${x}`,
        value: this.students.filter(({ yearLevel }) => yearLevel === x).length,
        color: "pink",
      }));

    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.map(({ label }) => label),
        datasets: [
          {
            data: data.map(({ value }) => value),
            backgroundColor: data.map(({ color }) => color),
          },
        ],
      },
      options: {
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: "line",
                xMin: this.benchmark,
                xMax: this.benchmark,
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 3,
                borderDash: [20],
                label: {
                  enabled: true,
                  content: this.benchmarkLabel,
                  rotation: -90,
                  backgroundColor: "white",
                  color: "rgb(255, 99, 132)",
                },
              },
            },
          },
        },
        scales: {
          y: {
            max: Math.max(...data.map(({ value }) => value)) + 1,
            beginAtZero: true,
            title: {
              display: true,
              text: this.yAxisLabel,
              font: {
                size: 24,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: this.xAxisLabel,
              font: {
                size: 24,
              },
            },
          },
        },
      },
    });
  }
}
