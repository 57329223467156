























































































import { Component, Prop, Vue } from "vue-property-decorator";
import type { Student } from "@/types";

const array1to = (length: number) => Array.from({ length }, (_, i) => i * 1);

@Component
export default class ListView extends Vue {
  @Prop({ default: [] }) readonly students!: Array<Student>;
  @Prop({ default: 6 }) readonly columns!: number;

  get rows(): Array<{ id: number; students: Array<Student> }> {
    return array1to(Math.ceil(this.students.length / this.columns)).map(
      (i) => ({
        id: i + 1,
        students: this.students.slice(i * this.columns, (i + 1) * this.columns),
      })
    );
  }
}
