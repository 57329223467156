





































































































import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
  }),
});
