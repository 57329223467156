import type { Student } from "@/types";

export const studentFixtures: Array<Student> = [
  {
    id: 1,
    name: "Hastings Levings",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 2,
    name: "Dita Pilley",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 3,
    name: "Stacey Haggerwood",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 4,
    name: "Bent Falco",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 5,
    name: "Garik Scutts",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 6,
    name: "Britt Josipovitz",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 7,
    name: "Holly Goodnow",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 8,
    name: "Estella Birrane",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 9,
    name: "Sunshine Isgar",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 10,
    name: "Aeriela Brant",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 11,
    name: "Robert Pembery",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 12,
    name: "Chelsey Grabbam",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 13,
    name: "Jerrilyn Gheorghescu",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 14,
    name: "Gerek Gaynes",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 15,
    name: "Coreen Daniels",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 16,
    name: "Salvatore Shivlin",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 17,
    name: "Ado Zielinski",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 18,
    name: "Gigi Marquet",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 19,
    name: "Kennie Gwioneth",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 20,
    name: "Lena Balfour",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 21,
    name: "Barnard Klement",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 22,
    name: "Fredericka Harlow",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 23,
    name: "Kitty Kenchington",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 24,
    name: "Rivi Sprules",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 25,
    name: "Glyn Arboine",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 26,
    name: "Hi D'Almeida",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 27,
    name: "Zackariah Mounsie",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 28,
    name: "Penn Wrack",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 29,
    name: "Raimondo Dmitrievski",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 30,
    name: "Benson Tewnion",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 31,
    name: "Hagen Fowls",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 32,
    name: "Adelheid Badcock",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 33,
    name: "Marna Jaram",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 34,
    name: "Carlynn Lechelle",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 35,
    name: "Sophey Macbeth",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 36,
    name: "Emmalynn Lynthal",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 37,
    name: "Niven Skillman",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 38,
    name: "Franciskus Brunker",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 39,
    name: "Dorolisa Grebert",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 40,
    name: "Matty Pendergast",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 41,
    name: "Duane Hunnable",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 42,
    name: "Karyn Parkhouse",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 43,
    name: "Corbie Girauld",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 44,
    name: "Zolly Bernolet",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 45,
    name: "Dino Spere",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 46,
    name: "Jana Fairham",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 47,
    name: "Sharl Jacqueminet",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 48,
    name: "Wini Bland",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 49,
    name: "Kendre Crackett",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 50,
    name: "Genia Redit",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 51,
    name: "Ramona Livick",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 52,
    name: "Mychal LAbbet",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 53,
    name: "Pearla Cosbey",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 54,
    name: "Tedd Kock",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 55,
    name: "Bessy Kesper",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 56,
    name: "Wes Yantsev",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 57,
    name: "Merry Huriche",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 58,
    name: "Cyb O'Carmody",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 59,
    name: "Chrisse Moncreiffe",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 60,
    name: "Henryetta Aluard",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 61,
    name: "Bone McCurley",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 62,
    name: "Cy Darell",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 63,
    name: "Arluene Croson",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 64,
    name: "Fedora Mercey",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 65,
    name: "Michaella Giacaponi",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 66,
    name: "Titus Delue",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 67,
    name: "Merry Jaycox",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 68,
    name: "Ynes Clubbe",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 69,
    name: "Ardenia Tremeer",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 70,
    name: "Ulrikaumeko Lisciandri",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 71,
    name: "Terencio Hugnet",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 72,
    name: "Ava Hurdidge",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 73,
    name: "Trina Nickels",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 74,
    name: "Thorn Cuttell",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 75,
    name: "Jacki McGerr",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 76,
    name: "Valentine Dolder",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 77,
    name: "Daffi Hamly",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 78,
    name: "Sophi Franzotto",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 79,
    name: "Ronalda Espinola",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 80,
    name: "Julita Bartolomeu",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 81,
    name: "Nesta Speer",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 82,
    name: "Virginia Kobpa",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 83,
    name: "De witt Cotmore",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 84,
    name: "Gena Tremontana",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 85,
    name: "Dunstan Beadman",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 86,
    name: "Paxton Shreenan",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 87,
    name: "Elden Raffon",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 88,
    name: "Virgina Bontoft",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 89,
    name: "Vonni Thewlis",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 90,
    name: "Urbano Jest",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 91,
    name: "Jeff McRill",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 92,
    name: "Ernie Lunck",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 93,
    name: "Flin Drewitt",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 94,
    name: "Shoshanna Fairbourn",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 95,
    name: "Anatola Romain",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 96,
    name: "Zackariah McAdam",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 97,
    name: "Adriane Opdenort",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 98,
    name: "Norean Gallamore",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 99,
    name: "Luke Taft",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 100,
    name: "Elinor Muslim",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 101,
    name: "Romonda Aggs",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 102,
    name: "Barnaby Gabbett",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 103,
    name: "Jori Borzoni",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 104,
    name: "Brod Wear",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 105,
    name: "Livia Beardwell",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 106,
    name: "Mae Holyland",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 107,
    name: "Verla Esser",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 108,
    name: "Morgan Brear",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 109,
    name: "Kerr Simmill",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 110,
    name: "Xylina Capon",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 111,
    name: "Ermina Rehorek",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 112,
    name: "Lotte Hemphrey",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 113,
    name: "Foster Wheatley",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 114,
    name: "Lorrie Tetsall",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 115,
    name: "Elia Larraway",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 116,
    name: "Kimmy Bubear",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 117,
    name: "Chrissy Janes",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 118,
    name: "Merissa O' Kelleher",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 119,
    name: "Rosette Schwand",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 120,
    name: "Nannette McCotter",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 121,
    name: "Muhammad Dowdle",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 122,
    name: "Jacquetta Riddeough",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 123,
    name: "Ferdinand Abramamovh",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 124,
    name: "Simonette Hodcroft",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 125,
    name: "Tamarra Phelip",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 126,
    name: "Cad Jeannesson",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 127,
    name: "Gran Bartomieu",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 128,
    name: "Antin Lidstone",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 129,
    name: "Putnem Bullier",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 130,
    name: "Giovanni Primarolo",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 131,
    name: "Davide Signorelli",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 132,
    name: "Wilmer Samuel",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 133,
    name: "Mill Edwards",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 134,
    name: "Jilli Runham",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 135,
    name: "Stuart Febre",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 136,
    name: "Wade Lorriman",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 137,
    name: "Stacia Kellie",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 138,
    name: "Tamara Bushell",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 139,
    name: "Matelda Hopkynson",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 140,
    name: "Carson Birkwood",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 141,
    name: "Emmy Kynoch",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 142,
    name: "Zaccaria Galea",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 143,
    name: "Quinton Oubridge",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 144,
    name: "Florian Olliar",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 145,
    name: "Giffer Thripp",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 146,
    name: "Judie Gabits",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 147,
    name: "Monah Gilpin",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 148,
    name: "Glenine Doggrell",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 149,
    name: "Aloysia Carrack",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 150,
    name: "Kerry Tomaskunas",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 151,
    name: "Spike Longfellow",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 152,
    name: "Hyacinthie Belhome",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 153,
    name: "Monroe Spavon",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 154,
    name: "Vinny Denington",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 155,
    name: "Candi Illingsworth",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 156,
    name: "Rosetta Abelov",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 157,
    name: "Tudor Skahill",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 158,
    name: "Shurlock Steketee",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 159,
    name: "Hedda Hedger",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 160,
    name: "Krystle Salliere",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 161,
    name: "Olva Bleakman",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 162,
    name: "Elspeth Domleo",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 163,
    name: "Rycca Markl",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 164,
    name: "Joey Fritschel",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 165,
    name: "Kitti Cork",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 166,
    name: "Maurizio Skade",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 167,
    name: "Anni Breeds",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 168,
    name: "Lilli Lage",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 169,
    name: "Jasmine Gallgher",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 170,
    name: "Chane Hudspith",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 171,
    name: "Nathan Girard",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 172,
    name: "Fernande Peggram",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 173,
    name: "Talbert Waywell",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 174,
    name: "Dela Foxcroft",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 175,
    name: "Angel Macias",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 176,
    name: "Ros Garrud",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 177,
    name: "Ali Mee",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 178,
    name: "Ali Peyton",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 179,
    name: "Findley Ketton",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 180,
    name: "Brooke McClancy",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 181,
    name: "Clary McCorkell",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 182,
    name: "Claiborne Lisle",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 183,
    name: "Melba Edwardson",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 184,
    name: "Marion Padson",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 185,
    name: "Thedrick Chrichton",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 186,
    name: "Torrey Wigglesworth",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 187,
    name: "Friedrick Bromilow",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 188,
    name: "Rozella Legate",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 189,
    name: "Jonathan Hanscome",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 190,
    name: "Obadiah Desbrow",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 191,
    name: "Mirna Hardwin",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 192,
    name: "Heinrick Talby",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 193,
    name: "Amber Volkers",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 194,
    name: "Della McCorry",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 195,
    name: "Bevin Bjorkan",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 196,
    name: "Georgina Loveguard",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 197,
    name: "Keen Elleton",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 198,
    name: "Dickie Witz",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 199,
    name: "Mercie Casson",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 200,
    name: "Maribelle Kirkness",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 201,
    name: "Ralf Gush",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 202,
    name: "Yevette Hannabuss",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 203,
    name: "Else Dunrige",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 204,
    name: "Sofie Goodbairn",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 205,
    name: "Johnathan Greedy",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 206,
    name: "Xever Godain",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 207,
    name: "Stefanie Kiley",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 208,
    name: "Madel Fraanchyonok",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 209,
    name: "Tedi Dyne",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 210,
    name: "Brooks Stovell",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 211,
    name: "Shara Hubble",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 212,
    name: "Damaris Shenton",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 213,
    name: "Guthry Villiers",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 214,
    name: "Roma Harbidge",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 215,
    name: "Johnette Ciccottio",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 216,
    name: "Sallyanne Worters",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 217,
    name: "Torrence Brightey",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 218,
    name: "Jim Mousley",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 219,
    name: "Urbain Sancraft",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 220,
    name: "Donn MacCathay",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 221,
    name: "Thomasine Shepeard",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 222,
    name: "Glynnis Fawley",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 223,
    name: "Daisy Coke",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 224,
    name: "Anson Maasze",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 225,
    name: "Shepard Guiraud",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 226,
    name: "Jasmina Feilden",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 227,
    name: "Lou Purdie",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 228,
    name: "Townie Forge",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 229,
    name: "Missy Parbrook",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 230,
    name: "Franni Casbon",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 231,
    name: "Dallas Alltimes",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 232,
    name: "Quinn Eckley",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 233,
    name: "Roderigo Rickford",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 234,
    name: "Camel Rawls",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 235,
    name: "Kasper Kenion",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 236,
    name: "Maxy Keggins",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 237,
    name: "Matt Nadin",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 238,
    name: "Arty Leeman",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 239,
    name: "Madalyn Leach",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 240,
    name: "Shayne Esposita",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 241,
    name: "Glynda Rosbotham",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 242,
    name: "Calhoun Minihane",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 243,
    name: "Virginia Elmar",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 244,
    name: "Margarette Dibbe",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 245,
    name: "Darcee MacVicar",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 246,
    name: "Lexy Balkwill",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 247,
    name: "Denny Weaving",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 4,
  },
  {
    id: 248,
    name: "Hieronymus Benediktsson",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 7,
  },
  {
    id: 249,
    name: "Lawton Stobbie",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 250,
    name: "Rudd Jirka",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 251,
    name: "Trenna Burrel",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 252,
    name: "Alane Le Friec",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 253,
    name: "Devonne Boardman",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 254,
    name: "Elmira Lovstrom",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 255,
    name: "Annissa Kelway",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 256,
    name: "Davidde Pisculli",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 257,
    name: "Doretta Puttrell",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 258,
    name: "Dorene Cheatle",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 259,
    name: "Breena Brane",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 260,
    name: "Reynold Thynn",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 261,
    name: "Judye Scarff",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 262,
    name: "Mathe Elnough",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 263,
    name: "Brook Durnin",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 264,
    name: "Alejandro Bluschke",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 265,
    name: "Clay de la Valette Parisot",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 266,
    name: "Carce Delnevo",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 267,
    name: "Antonina Antecki",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 268,
    name: "Broddy Jonas",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 269,
    name: "Tann Baldocci",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 270,
    name: "Chase Oulet",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 271,
    name: "Halli Baddam",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 1,
  },
  {
    id: 272,
    name: "Wallie Cockshott",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 273,
    name: "Nathalie Row",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 274,
    name: "Glori Wolfendale",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 275,
    name: "Letisha Lohoar",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 276,
    name: "Alaric Bullar",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 277,
    name: "Bernice Stokes",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 278,
    name: "Leila Heam",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 279,
    name: "Anthiathia Vize",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 280,
    name: "Thalia Piggford",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 281,
    name: "Sheilakathryn McLise",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 4,
  },
  {
    id: 282,
    name: "Carissa Kleinlerer",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 2,
  },
  {
    id: 283,
    name: "Kaela Eamer",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 1,
  },
  {
    id: 284,
    name: "Garth Fattorini",
    fundingA: true,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 285,
    name: "Tobi Kitchaside",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 286,
    name: "Ginelle Dunthorn",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: true,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 287,
    name: "Andy Assad",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 288,
    name: "Lewie Androsik",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 289,
    name: "Cos Letertre",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 2,
  },
  {
    id: 290,
    name: "Bonnibelle Saurat",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 291,
    name: "Seymour Obert",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 7,
  },
  {
    id: 292,
    name: "Margi Messiter",
    fundingA: true,
    fundingB: false,
    fundingC: true,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 6,
  },
  {
    id: 293,
    name: "Brock Lerway",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: false,
    filter2: false,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 294,
    name: "Danica Hurdman",
    fundingA: true,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 3,
  },
  {
    id: 295,
    name: "Peadar Cordeau]",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 3,
  },
  {
    id: 296,
    name: "Edward Chismon",
    fundingA: false,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 297,
    name: "Johann Nickell",
    fundingA: false,
    fundingB: false,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 6,
  },
  {
    id: 298,
    name: "Pierson Stanway",
    fundingA: false,
    fundingB: false,
    fundingC: true,
    filter1: false,
    filter2: true,
    filter3: true,
    yearLevel: 5,
  },
  {
    id: 299,
    name: "Hank Madgewick",
    fundingA: true,
    fundingB: true,
    fundingC: false,
    filter1: true,
    filter2: false,
    filter3: false,
    yearLevel: 5,
  },
  {
    id: 300,
    name: "Marcelle Lauchlan",
    fundingA: false,
    fundingB: true,
    fundingC: true,
    filter1: true,
    filter2: true,
    filter3: false,
    yearLevel: 4,
  },
];
